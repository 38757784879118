/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Con la aparición del nuevo ", React.createElement(_components.a, {
    href: "http://es.wikipedia.org/wiki/HTML_5",
    title: "HTML 5"
  }, "HTML 5"), ", se van a estandarizar los formatos multimedia en la web en base a formatos libres como ", React.createElement(_components.a, {
    href: "http://es.wikipedia.org/wiki/Ogg",
    title: "Ogg"
  }, "Ogg"), ". De esta forma se pueden dejar de utilizar formatos privativos como Flash, que se ha estado utilizando hasta ahora."), "\n", React.createElement(_components.p, null, "En el siguiente video vemos las posibilidades de HTML 5 en el navegador Firefox 3.5 que aun está en versión beta. En el video podemos ver las interesantes opciones que tiene como poder redimensionar y girar los videos mientras se reproducen o incluir enlaces."), "\n", React.createElement(_components.p, null, "[youtube=http://www.youtube.com/watch?v=3tLBLVtIk3A&feature=player_embedded]"), "\n", React.createElement(_components.p, null, "Y la gran noticia para los defensores de los formatos libres es que ya podemos disfrutar de la version de ", React.createElement(_components.a, {
    href: "http://www.youtube.com/html5",
    title: "Youtube"
  }, "Youtube"), " con este nuevo estandard de HTML. Pero para esto necesitaremos un navegador apropiado como puede ser Firefox 3.5."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
